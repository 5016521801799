/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react"
import { useState, useRef } from "react"
import {createTheme, ThemeProvider} from "@mui/material/styles"
import { StyledEngineProvider } from '@mui/material/styles'

import "./layout.css"
import { PaletteMode } from "@mui/material"
import Box from "@mui/material/Box"


declare module '@mui/material/styles' {
  interface Palette {
    velvet: Palette["primary"];
    pacificPink: Palette["primary"];
    calmingCoral: Palette["primary"];
    veryPeri: Palette["primary"];
    vivaMagenta: Palette["primary"]; // 2023
    peachFuzz: Palette["primary"]; // 2024
    sectionBgDark: Palette["primary"];
    sectionBgLight: Palette["primary"];
  }
  interface PaletteOptions {
    velvet: PaletteOptions["primary"];
    pacificPink: PaletteOptions["primary"];
    calmingCoral: PaletteOptions["primary"];
    veryPeri: Palette["primary"];
    vivaMagenta: Palette["primary"];
    peachFuzz: Palette["primary"];
    sectionBgDark?: Palette["primary"];
    sectionBgLight?: Palette["primary"];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    velvet: true;
    pacificPink: true;
    calmingCoral: true;
    veryPeri: true;
    vivaMagenta: true;
    peachFuzz: true;
    sectionBgDark: true;
    sectionBgLight: true
  }
}

export const ThemeContext = React.createContext(null)
export const AuthUserContext = React.createContext<any>(null)
export const FirebaseContext = React.createContext(null)
export const LayoutContext = React.createContext({
  toggleColorMode: () => {},
})

const Layout:React.FC<{ children: any }> = ({ children }) => {
  const [mode, setMode] = useState<PaletteMode>('dark');
  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
    }),
    [],
  );
  // Update the theme only if the mode changes
  const theme = React.useMemo(
    () => {
      let initTheme = createTheme ({
        palette: {
          mode,
          primary: {
            light: '#f35d74',
            main: '#BB2649',
            dark: '#850022',
            contrastText: '#fff',
          },
          secondary: {
            light: '#ffa1c3',
            main: '#db7093',
            dark: '#a74165',
            contrastText: '#000',
          },
          velvet: {
            light: '#b343b0',
            main: '#800080',
            dark: '#4f0053',
            contrastText: '#fff',
          },
          pacificPink: {
            light: '#ffa1c3',
            main: '#db7093',
            dark: '#a74165',
            contrastText: '#000',
          },
          calmingCoral: {
            light: '#ffc7a9',
            main: '#e9967a',
            dark: '#b4674e',
            contrastText: '#000',
          },
          veryPeri: {
            light: '#6667ab',
            main: '#9695dd',
            dark: '#373d7b',
            contrastText: '#fff',
          },
          vivaMagenta: {
            light: '#f35d74',
            main: '#BB2649',
            dark: '#850022',
            contrastText: '#fff',
          },
          peachFuzz: {
            light: '#fed7c1',
            main: '#ffbe98',
            dark: '#dc6c10',
            contrastText: '#fff',
          },
          // sectionBgDark: {
          //   light: '#302c4f', // '#2e2a67',
          //   main: '#14161e', // '#00003c',
          //   dark: '#000000', // '#000019',
          //   contrastText: '#fff',
          // },
          // sectionBgLight: {
          //   light: '#fff',
          //   main: '#f3f6f9',
          //   dark: '#c0c3c6',
          //   contrastText: '#000',
          // },
          ...(mode === 'dark' ? {
            background: {
              default: '#0e0f14', //'#0a0027', //'#00174e',
              paper: '#fff',
            },
          } : {}),
        },
        typography: {
          fontFamily: [
            'Hack',
            'Georgia',
            'serif'
          ].join(','),
        },
        // breakpoints: { // down: max-width, up: min-width
        //   values: {
        //     xs: 0,
        //     sm: 600,
        //     md: 900,
        //     lg: 1200,
        //     xl: 1536,
        //   },
        // },
      })
      return createTheme(initTheme, {
        palette: {
          sectionBgDark: initTheme.palette.augmentColor({
            color: {
              main: '#14161e', // '#00003c',
            },
            name: 'sectionBgDark'
          }),
          sectionBgLight: initTheme.palette.augmentColor({
            color: {
              main: '#f3f6f9',
            },
            name: 'sectionBgLight'
          }),
        }
      })
    },
    [mode],
  );

  const isBrowser = typeof window !== "undefined"
  const mouse = useRef([0, 0])
  return (
      <LayoutContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <Box
              component="div"
              sx={{
                bgcolor: 'background.default',
                color: 'text.primary',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {/* <Header /> */}
              {children}
            </Box>
          </StyledEngineProvider>
        </ThemeProvider>
      </LayoutContext.Provider>
  )
}

export default Layout
